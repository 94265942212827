import logo from "./logo.png"

const clientData = {
    client_entity: 46,
    attorney_firm: 'Rawling & MacInnis',
    attorney_name: 'Jeff Rawlings',
    attorney_number: '601 898-1180',
    attorney_email: 'jeff@rawlingsmacinnis.net',
    client_video_source: 'https://erc-videos.s3.amazonaws.com/ertc_-_jeff_rawlings+(720p)+(1).mp4',
    mainColor: '#242424',
    secondaryColor: '#263F5B',
    siteLink: 'http://www.frascognalaw.com/',
    logo
}

export default clientData